$accent: #2185d0;
$font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

// Data management
@import "~@progress/kendo-theme-bootstrap/scss/grid";

// Editors
@import "~@progress/kendo-theme-bootstrap/scss/editor";
@import "~@progress/kendo-theme-bootstrap/scss/upload"; // upload depends on editor

.k-widget .ui.grid,
.k-grid .ui.grid {
    // semantic grids won't take 100% width without when inside kendo components
    flex-grow: 1;
}

.k-grid-header .wrap-header {
    height: auto;
    overflow: visible;
    white-space: normal;
}

.k-grid-no-wrap td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.k-grid-less-padding th,
.k-grid-less-padding td {
    padding: 0.3rem !important;
    font-size: 0.9em !important;
}

.k-grid-vertical-align-top td {
    vertical-align: top;
}

.k-grid-no-hover tr:hover {
    background: transparent !important;
}

.k-grid-muted-pager .k-pager-numbers .k-link.k-state-selected {
    border-color: unset !important;
    color: black;
    background-color: #e4e7eb;
}

.k-grid-small-pager .k-pager-wrap {
    padding: 0.25rem !important;
}

.k-grid-small-pager .k-grid-pager {
    font-size: 0.8em !important;
}

.k-grid-small-pager .k-input {
    padding-left: 0.5rem;
    padding-right: 0.25rem;
    height: unset !important;
}

.k-grid-small-pager .k-select {
    padding-left: 1px;
    padding-right: 1px;
}

@media (max-width: 1500px) {
    .k-grid-width-1500 {
        width: 100%;
    }
}

@media (min-width: 1501px) {
    .k-grid-width-1500 {
        width: 80%;
    }
}
