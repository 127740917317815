$lightgrey: rgba(34, 36, 38, 0.15);
$black: #000;

.display-inline {
    display: inline !important;
}

.display-block {
    display: block !important;
}

.text-muted {
    color: #9497a0 !important;
}

.text-bold {
    font-weight: bold !important;
}

.text-normal {
    font-weight: normal !important;
}

.text-danger {
    color: #a94442 !important;
}

.text-red {
    color: #f00 !important;
}

.text-semantic-red {
    color: #db2828 !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-green {
    color: #090;
}

.text-black {
    color: $black !important;
}

.text-1rem {
    font-size: 1rem !important;
}

.text-12 {
    font-size: 12px !important;
}

.float-right {
    float: right;
}

.clickable {
    cursor: pointer;
}

.card-color-border {
    border: 1px solid #2185d0a8 !important;
    box-shadow: 0px 3px 5px 1px #eee !important;
}

.form-disabled input,
.form-disabled textarea,
.form-disabled .search,
.form-disabled .selection,
.form-disabled .dropdown {
    background-color: #f4f4f4 !important;
    pointer-events: none;
}

.bg-light-grey {
    background-color: #f7f7f7;
}

.border-light-grey {
    border: 2px solid $lightgrey;
}

.border-bottom-light-grey {
    border-bottom: 2px solid $lightgrey;
}

.no-wrap {
    white-space: nowrap !important;
}

.table-no-borders td {
    border-top: 0px !important;
}

.ui.fullscreen.modal,
.ui.fullscreen.scrolling.modal {
    /* https://github.com/Semantic-Org/Semantic-UI/issues/6267 */
    /* fullscreen modal is not centered horizontally w/o this selector fix */
    left: unset !important;
}

.ui.basic.striped.table tbody tr:nth-child(2n) {
    background-color: #f9f9f9 !important;
}

.tab-12 .ui.attached.tabular.menu a {
    font-size: 12px !important;
}

@media (max-width: 1400px) {
    .table-1rem-less-padding,
    .responsive-table {
        font-size: 11px !important;
    }
}

@media (min-width: 1401px) and (max-width: 1700px) {
    .table-1rem-less-padding,
    .responsive-table {
        font-size: 12px !important;
    }
}

@media (min-width: 1701px) {
    .table-1rem-less-padding,
    .responsive-table {
        font-size: 13px !important;
    }
}

@media (max-width: 1400px) {
    .table-cell-only-large-screen {
        display: none;
    }
}

@media (min-width: 1401px) {
    .table-cell-only-large-screen {
        display: table-cell;
    }
}

@media (max-width: 1500px) {
    .width-1500 {
        width: 100% !important;
    }
}

@media (min-width: 1501px) {
    .width-1500 {
        width: 80% !important;
    }
}

.table-1rem-less-padding,
.responsive-table {
    color: $black !important;
}

.table-1rem-less-padding td {
    padding: 0.2em 0.6em !important;
}

.very-basic-table td {
    border: none !important;
    color: $black !important;
}

.very-basic-segment {
    box-shadow: none !important;
}

.arial-12-black {
    font-family: Arial, sans-serif !important;
    font-size: 12px !important;
    color: #000 !important;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-label {
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
}

.link-opacity-7 > a {
    opacity: 0.7 !important;
}

.link-opacity-7 > a:hover {
    opacity: 1 !important;
}

.insured-fixed-content {
    position: fixed;
    width: calc(100% - 28px);
    z-index: 3;
}

.blue-link {
    color: #2185d0 !important;
}

.ui.search > .results {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    margin-right: -85px;
}

.ui.category.search > .results .category > .name {
    color: $black !important;
}

// faceted filter style
$font-size: 12px;
@import "node_modules/@itm21st/faceted-filter/dist/style.scss";

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25
        ),
        2: (
            $spacer * 0.5
        ),
        3: $spacer,
        4: (
            $spacer * 1.5
        ),
        5: (
            $spacer * 3
        )
    ),
    $spacers
);

@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {
        .#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }

        .#{$abbrev}t-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-top: $length !important;
        }

        .#{$abbrev}r-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-right: $length !important;
        }

        .#{$abbrev}b-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-bottom: $length !important;
        }

        .#{$abbrev}l-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-left: $length !important;
        }
    }
}
